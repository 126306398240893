import { MeContext } from "@/context/me";
import { useContext, useMemo } from "react";

export const useMeContext = () => {
  const context = useContext(MeContext);
  if (!context) {
    throw new Error("useMeContext must be used within a MeProvider");
  }
  return context;
};

export const useMe = () => {
  const { data } = useMeContext();
  if (!data) {
    throw new Error("Me data is not available");
  }
  return useMemo(() => data, [data]);
};

export const useCaregiver = () => {
  const { caregiverData } = useMeContext();
  if (!caregiverData) {
    throw new Error("Caregiver data is not available");
  }
  return useMemo(() => caregiverData, [caregiverData]);
};

export const useCaregiverCountry = () => {
  const caregiver = useCaregiver();
  return useMemo(() => caregiver.country, [caregiver]);
};