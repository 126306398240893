import {
  CountryCode,
  PhoneNumber,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberWithError,
} from "libphonenumber-js";
import { useCaregiverCountry } from "./me-context";
import { useCallback } from "react";

// regExp for check short phone number like *87 or 12345;
const shortPhoneNumber = /^([*|\d][\d+]{2,7})$/g;

export const isShortCodeNumber = (phoneNumber: string): boolean => {
  if (phoneNumber.startsWith("*")) {
    return true;
  }
  return phoneNumber.match(shortPhoneNumber) !== null;
};

export const formatPhoneNumberByCountry = (phoneNumber: string, defaultCountryCode: string = "US"): string => {
  try {
    if (isShortCodeNumber(phoneNumber)) {
      return phoneNumber;
    }

    const parsedPhoneNumber: PhoneNumber | undefined = parsePhoneNumberWithError(phoneNumber, defaultCountryCode as CountryCode);

    if (parsedPhoneNumber.country === defaultCountryCode) {
      return parsedPhoneNumber.formatNational();
    }

    return parsedPhoneNumber.formatInternational();
  } catch (error) {
    return phoneNumber;
  }
};

export const parsePhoneNumberByCountry = (phoneNumber: string, defaultCountry: string = "US") => {
  if (!phoneNumber) {
    return {
      countryCode: defaultCountry,
      nationalNumber: "",
      e164Number: "",
      isValid: false,
    };
  }

  if (isShortCodeNumber(phoneNumber)) {
    return {
      countryCode: defaultCountry,
      nationalNumber: phoneNumber,
      e164Number: phoneNumber,
      isValid: true,
    };
  }

  let countryDialCode: string;

  try {
    countryDialCode = getCountryCallingCode(defaultCountry as CountryCode);
  } catch (e) {
    throw new Error(`Invalid country code: ${defaultCountry}`);
  }

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, defaultCountry as CountryCode);
    const nationalNumber = parsedPhoneNumber?.nationalNumber || phoneNumber.replace(new RegExp(`^\\+${countryDialCode}`), "");
    const e164Number = parsedPhoneNumber?.number || `+${countryDialCode}${nationalNumber.replace(/[^\d]/g, "")}`;

    return {
      countryCode: parsedPhoneNumber?.country || defaultCountry,
      nationalNumber,
      e164Number,
      isValid: parsedPhoneNumber?.isValid() || false,
    };
  } catch (e) {
    return {
      countryCode: defaultCountry,
      nationalNumber: phoneNumber,
      e164Number: phoneNumber,
      isValid: isValidPhoneNumber(phoneNumber, defaultCountry as CountryCode) || isPossiblePhoneNumber(phoneNumber, defaultCountry as CountryCode),
    };
  }
};

export const useFormatPhoneNumberByCountry = () => {
  const caregiverCountry = useCaregiverCountry();

  return useCallback(
    (phoneNumber: string) => {
      if (!phoneNumber) {
        return "";
      }

      return formatPhoneNumberByCountry(phoneNumber, caregiverCountry);
    },
    [caregiverCountry],
  );
};

export const useParsePhoneNumberByCountry = () => {
  const caregiverCountry = useCaregiverCountry();

  return useCallback(
    (phoneNumber: string) => {
      if (!phoneNumber) {
        return {
          countryCode: caregiverCountry || "",
          nationalNumber: "",
          e164Number: "",
          isValid: false,
        };
      }

      return parsePhoneNumberByCountry(phoneNumber, caregiverCountry);
    },
    [caregiverCountry],
  );
};
