import { useState } from "react";
import { updateChildConfig, updateDeviceInfo } from "../../util/children-helper";
import { Box, Typography } from "@mui/material";
import { ChildResponse } from "../../openapi";
import ModalWindow from "../ModalWindow";
import { useAlert } from "@/hooks/useAlert";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { useCaregiverCountry } from "@/util/me-context";

interface ModalProps {
  isModalShown: boolean;
  setShowModal: (arg: boolean) => void;
  child: ChildResponse;
}


const ModalEnableGroupTexting = ({ isModalShown, setShowModal, child }: ModalProps) => {
  const caregiverCountryCode = useCaregiverCountry();
  // Currently our system designed for single device per child, so setting one phone number
  const hasAnyDevice = child.deviceInfos.some(deviceInfo => !!deviceInfo.fcmDeviceToken);
  const [phoneNumber, setPhoneNumber] = useState(
    hasAnyDevice && child.deviceInfos && child.deviceInfos[0].phoneNumber
      ? child.deviceInfos[0].phoneNumber
      : ""
  );
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const alert = useAlert();

  const updateChildPhone = async () => {
    let phoneError = false;
    if (hasAnyDevice) {
      const data = await updateDeviceInfo(child.id, {
        ...child.deviceInfos[0],
        phoneNumber,
      });

      if (data) {
        const updatedChild = {
          ...child,
          deviceInfos: [
            ...child.deviceInfos.filter(device => device.deviceId !== data.deviceId),
            data
          ]
        };
        Object.assign(child, updatedChild);
      } else {
        phoneError = true;
        alert.error("Failed to update child's phone number. Reload to try again.");
      }
    }

    if (!phoneError) {
      const data = await updateChildConfig(child.id, {
        ...child.configuration,
        allowGroupMessaging: true
      });
      if (data) {
        const updatedChild = { ...child, configuration: data };
        Object.assign(child, updatedChild);
        alert.success("Group messaging enabled.");
      } else {
        alert.error("Failed to enable group messaging. Reload to try again.");
      }
    }
  };

  const handleSave = async (): Promise<void> => {
    try {
      await updateChildPhone();
      setShowModal(false);
    } catch (error) {
      alert.error("Failed to enable group messaging. Reload to try again.");
    }
  };

  const getModalContent = () => (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <p>Please enter {child.firstName}’s phone number to enable group texting on the Pinwheel phone.</p>
      <PhoneNumberInput
        label="Phone Number"
        countryCode={caregiverCountryCode}
        onChange={(value, { isValid }) => {
          setPhoneNumber(value);
          setIsPhoneNumberValid(isValid);
        }}
      />
      <Typography component={"p"} sx={{ fontSize: "0.8em", fontStyle: "italic", opacity: "0.8" }}>
        We were unable to retrieve the child’s phone number from the SIM card. We need the number to approve it in the group, long story!
      </Typography>
    </Box>
  );

  return (
    <ModalWindow
      headerText="Enable Group Texting"
      showModal={isModalShown}
      setShowModal={setShowModal}
      renderBody={getModalContent}
      onActionButtonPress={handleSave}
      actionButtonType="primary"
      actionButtonText="Save"
      confirmDisabled={!isPhoneNumberValid}
    />
  );
};

export default ModalEnableGroupTexting;
